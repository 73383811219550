<template>
  <div class="grid">
    <div class="col-12">

      <Panel>
        <template #header>
          <b>Fiyatlar</b>
        </template>
        <p>
          <DataTable
              :value="datasource"
              :paginator="true"
              sortMode="multiple"
              showGridlines
              :rows="10"
              :loading="loadingList"
              filterDisplay="menu"
              v-model:filters="filters"
              dataKey="id"
              :globalFilterFields="['name']"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <template #header>
        <span class="flex justify-content-between">
          <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Filtreleri Kaldır"
              class="p-button-outlined"
              @click="clearFilter()"
          />
          <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText
                      v-model="filters['global'].value"
                      placeholder="Aranacak Kelime"
                  />
                </span>
          </span>

            </template>
            <template #empty> Fiyat bulunamadı</template>
            <template #loading>
              Fiyat yükleniyor lütfen bekleyiniz.
            </template>
            <Column
                field="id"
                header="Id"
                :sortable="true"
                style="width: 130px"
            ></Column>
            <Column
                field="resourceName"
                header="Metal Adı"
                :sortable="true"
                style="width: 500px"
            >
              <template #filter="{ filterModel }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Aranacak..."
                />
              </template>
            </Column>
            <Column
                field="date"
                header="Tarih"
                :sortable="true"
                style="width: 500px"
            >
              <template #body="{ data }">
                {{ new Date(data.date).toLocaleDateString()}}</template>

            </Column>
            <Column
                field="price"
                header="Fiyat"
                :sortable="true"
                style="width: 500px"
            >
              <template #filter="{ filterModel }">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Aranacak..."
                />
              </template>
            </Column>
            <Column :exportable="false" style="width: 130px">
              <template #body="slotProps">
                <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-success mr-2"
                    @click="edit(slotProps.data)"
                />
              </template>
            </Column>
          </DataTable>
        </p>
        <p>
          <Button label="Yeni Fiyat Ekle" icon="pi pi-plus" @click="addNew"></Button>
        </p>
      </Panel>
    </div>
  </div>

</template>

<script>

import {FilterMatchMode, FilterOperator} from "primevue/api";
import ResourceDataService from "../../services/resourceDataService";

export default {
  _service: null,
  data() {
    return {
      datasource: [],
      loadingList: true,
      filters: null,
    }
  },
  created() {
    this.resourceId = this.$route.params.resourceId;
    this._service = new ResourceDataService();
    this.initFilters();
  },
  async mounted() {
    if (this.resourceId > 0) {
      const response = await this._service.getResourceDatasByResourceId(this.resourceId);
      this.datasource = response.data;
      this.loadingList = false;
    } else {
      const response = await this._service.getAllResourceDatas();
      this.datasource = response.data;
      this.loadingList = false;
    }

  },
  methods: {
    addNew() {
      this.$router.push({name: "resourceDataAdd"});
    },
    edit(item) {
      this.$router.push({name: "resourceDataEdit", params: {resourceDataId: item.id}});
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        resourceName: {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
        },
        date: {operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        isActive: {value: null, matchMode: FilterMatchMode.EQUALS}
      };
    },
  }
}

</script>